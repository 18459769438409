import React, { ReactElement } from "react"

interface IOption {
  value: any
  title?: string
}

interface Props {
  options?: IOption[]
  onChange?: any
  value?: any
  spread?: boolean
  autoSelect?: boolean
}

function Select({
  options = [],
  value,
  onChange,
  spread = false,
  autoSelect = true,
}: Props): ReactElement {
  const [val, setVal] = React.useState()
  React.useEffect(() => {
    if (onChange && options && options.length && autoSelect) {
      onChange(options[0].value)
      // setVal(options[0].value)
    }
  }, [])
  React.useEffect(() => {
    setVal(value)
  }, [value])
  const onOptionClick = (v: any) => (e: any) => {
    choose(v)
    if (onChange) {
      onChange(v)
    }
  }
  const choose = (v: any) => {
    setVal(v)
  }

  const changed = (e: any) => {
    const v = e.target.value
    choose(v)
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <div>
      {spread ? (
        <div className="flex flex-row flex-wrap justify-start">
          {options.map((opt: IOption, index: number) => (
            <div
              key={index}
              className=" w-full md:w-auto mb-2 mr-2 flex justify-center"
            >
              <div
                style={{ minWidth: "70px", border:"1px solid #cbcbcb" }}
                onClick={onOptionClick(opt.value)}
                className={
                  "cursor-pointer shadow-md p-3 w-full md:w-auto rounded flex justify-center items-center  " +
                  (val === opt.value
                    ? "bg-teal-700 text-white"
                    : "bg-gray-300 text-black")
                }
              >
                <span>{opt.title || opt.value}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <select
          onChange={changed}

          className="h-12 shadow-md  px-4  w-full  rounded-lg  text-gray-700"
        >
          {options.map((opt: IOption, index: number) => (
            <option value={opt.value}>{opt.title || opt.value}</option>
          ))}
        </select>
      )}
    </div>
  )
}

export default Select
