import React, { ReactElement } from "react";

export interface ButtonProps {
  title?: string;
  onClick?: any;
  size?: "sm" | "md" | "lg";
  style?:any;
  children?: any;
  className?: string;
}

export default function Button({
  title,
  onClick,
  size,
  style,
  children,
  className
}: ButtonProps): ReactElement | null {
  const onClickHandler = (e: any) => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      className={"bg-teal-400 shadow-md hover:bg-teal-300 text-white py-3 px-8 rounded-md "+" "+className}
      onClick={onClickHandler}
      style={style}
    >
      {children || title}
    </button>
  );
}
