import Axios from "axios"
import config from "../data/config"
import Moment from "moment"
const opts = {
  headers: {
    "content-type": "application/json",
  },
}
const Utils = {
  API: {
    post: async (uri: string, data: any) => {
      const url = config.backend + uri
      return await Axios.post(url, data, opts)
    },
    get: async (uri: string) => {
      const url = config.backend + uri
      return await Axios.get(url, opts)
    },
  },
  Commons: {
    substr: (content: string, maxLength = 100) => {
      let nContent = content
      try {
        nContent = nContent.replace(/<\/?[^>]+(>|$)/g, "")
        nContent =
          nContent.length && nContent.length > maxLength
            ? nContent.substr(0, maxLength) + "..."
            : nContent
      } catch (e) {}
      return nContent
    },
    scrollTo: (id: string) => {
      try {
        const ele = document.getElementById(id)
        if (ele) {
          const top = window.scrollY + ele.getBoundingClientRect().top - 200
          window.scrollTo({
            behavior: "smooth",
            top,
          })
        }
      } catch (e) {
        console.error(e + "")
      }
    },
    getQueryParam: (name: string, url: string) => {
      if (!url) url = window.location.href
      name = name.replace(/[\[\]]/g, "\\$&")
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ""
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    },
  },
  DateTime: Moment,
}
export default Utils
