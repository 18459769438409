import React, { ReactElement } from "react"

interface Props {
  visible?: boolean
}

function Loader({ visible }: Props): ReactElement {
  return visible ? (
    <div style={{backgroundColor:"rgba(255,255,255,0.6)"}} className="z-10 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center">
      <div className="shadow-lg rounded bg-white">
        <img className="w-24 h-24" src={require("../../images/loading.gif").default} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Loader
